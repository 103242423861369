.App {
  background-image: url('./images/1128.png');
  background-size: auto;
  background-repeat: repeat;
  min-height: 100vh;
  width: 99vw;
  position: absolute;
  border: 10px solid black;
  box-sizing: border-box; /* Ensures the border width doesn't add to the total width/height */
  padding: 20px; /* Adjust based on your preference */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  overflow: hidden; /* Hide scrollbars */

}

.App-header {
  padding: 60px;
  color: white;
  text-align: center;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Adjust this based on vertical alignment needs */
  overflow-x: hidden;
}



p {
  font-size: 24px;
}


.main-content {
  height: 100%;
  font-family: "Comic Sans MS", "Comic Sans", cursive, bold; /* Iconic '90s font */
  color: black;
  font-weight: bold;
  text-align: center;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Adjust this based on vertical alignment needs */
  width: 100%; /* Takes up full width */
}

.floating-container {
  position: relative;
  display: inline-block; /* This makes the container only as wide as its content */
  transform: scale(1); /* Adjust if you want the GIF to be smaller */
  animation: float 3s ease-in-out infinite;
}

.Website-Title {
  display: block; /* Adjust as needed */
  margin: 0 auto;
  width: auto; /* Adjust based on your image's size */
  max-width: 100%; /* Ensures the image is responsive */
}

.Floating-GIF {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%) translateX(100%) scale(1); /* Position it right at the bottom-right of the title image */
  /* Scale as needed */
}

.Floating-Money {
  position: absolute;
  top: 0; /* Changed from bottom to top */
  right: 0;
  transform: translateY(0%) translateX(100%) scale(0.5); /* Adjusted for top-right positioning */
  /* Scale as needed */
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}


@keyframes shake-and-change-color {
  0%, 100% {
    transform: translateX(0);
    color: red;
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
    color: red;
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
    color: red;
  }
}

.shake-and-change-color {
  display: inline-block; /* Allows the transform to work on inline elements */
  animation: shake-and-change-color 1s ease infinite;
}


/* Adding an inner border with the pseudo-element */
/*.links::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: -15px; !* Offset by the thickness of the border plus the desired gap *!*/
/*  right: -15px;*/
/*  bottom: -15px;*/
/*  left: -15px;*/
/*  border: 5px solid #FFA500; !* Inner orange border *!*/
/*  z-index: 0; !* Behind the content wrapper but above anything else *!*/
/*  box-shadow: 0 0 15px 5px #FFA500; !* Same glow effect to match the outer glow *!*/
/*}*/


.custom-cursor {
  /* Replace '/cigarette-cursor.png' with the path to your unlit cigarette image */
  cursor: url('./images/cursor.cur'), auto;
}

.top-right-gif {
  position: absolute; /* This positions the GIF absolutely within the .container div */
  top: 0; /* Align to the top edge of the .container */
  right: 0; /* Align to the right edge of the .container */
  z-index: 10; /* Adjust as necessary to ensure it stacks correctly */
}


@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start just outside the right edge */
  }
  100% {
    transform: translateX(-100%); /* End just outside the left edge */
  }
}

.scrolling-text-container {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  z-index: 10; /* Make sure this value is higher than the background's z-index */
  font-family: "Comic Sans MS", "Comic Sans", cursive, bold; /* Iconic '90s font */
  color: black;
}


@keyframes rainbow-text {
  0% { color: red; }
  14% { color: orange; }
  28% { color: yellow; }
  42% { color: green; }
  57% { color: blue; }
  71% { color: indigo; }
  85% { color: violet; }
  100% { color: red; }
}

.rainbow {
  animation: rainbow-text 8s linear infinite;
}



/* Styles for screens larger than 1200px (large desktops) */
@media (min-width: 1200px) {
  .links {
    max-width: 20.67%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;

    background-image: url('./images/1032.png');
    background-size: auto;
    background-repeat: repeat;
    overflow-wrap: break-word;


    /* Double border */
    border: 5px solid black; /* Outer black border */
    position: relative; /* Position relative for pseudo-element positioning */

    /* Orange glow effect */
    box-shadow: 0 0 10px 2px #FFA500; /* Horizontal offset, vertical offset, blur radius, spread radius, color */
  }


  .content-wrapper {
    max-width: 25.67%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;

    background-image: url('./images/1145.png');
    background-size: auto;
    background-repeat: repeat;


    /* Double border */
    border: 5px solid black; /* Outer black border */
    position: relative; /* Position relative for pseudo-element positioning */

    /* Orange glow effect */
    box-shadow: 0 0 10px 2px #FFA500; /* Horizontal offset, vertical offset, blur radius, spread radius, color */
  }

  /* Adding an inner border with the pseudo-element */
  .content-wrapper::before {
    content: '';
    position: absolute;
    top: -15px; /* Offset by the thickness of the border plus the desired gap */
    right: -15px;
    bottom: -15px;
    left: -15px;
    border: 5px solid #FFA500; /* Inner orange border */
    z-index: 0; /* Behind the content wrapper but above anything else */
    box-shadow: 0 0 15px 5px #FFA500; /* Same glow effect to match the outer glow */
  }


}

/* Styles for screens between 992px and 1199px (desktops) */
@media (max-width: 1199px) {
  .links {
    max-width: 80.67%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;

    background-image: url('./images/1032.png');
    background-size: auto;
    background-repeat: repeat;

    overflow-wrap: break-word;


    /* Double border */
    border: 5px solid black; /* Outer black border */
    position: relative; /* Position relative for pseudo-element positioning */

    /* Orange glow effect */
    box-shadow: 0 0 10px 2px #FFA500; /* Horizontal offset, vertical offset, blur radius, spread radius, color */
  }


  .content-wrapper {
    max-width: 90.67%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;

    background-image: url('./images/1145.png');
    background-size: auto;
    background-repeat: repeat;


    /* Double border */
    border: 5px solid black; /* Outer black border */
    position: relative; /* Position relative for pseudo-element positioning */

    /* Orange glow effect */
    box-shadow: 0 0 10px 2px #FFA500; /* Horizontal offset, vertical offset, blur radius, spread radius, color */
  }

  /* Adding an inner border with the pseudo-element */
  .content-wrapper::before {
    content: '';
    position: absolute;
    top: -15px; /* Offset by the thickness of the border plus the desired gap */
    right: -15px;
    bottom: -15px;
    left: -15px;
    border: 5px solid #FFA500; /* Inner orange border */
    z-index: 0; /* Behind the content wrapper but above anything else */
    box-shadow: 0 0 15px 5px #FFA500; /* Same glow effect to match the outer glow */
  }
}

@media (min-width: 470px) {
  .Joe-Camel-Image {
    height: 250px; /* Adjust based on your image */
    border: 3px solid #ffffff;
  }
}


@media (max-width: 469px) {
  .Joe-Camel-Image {
    height: 100px; /* Adjust based on your image */
    border: 3px solid #ffffff;
  }
}


